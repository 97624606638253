import imagePlaceholder from '../../assets/generic-avatar.png';

type AvatarSize = 'sm' | 'md' | 'lg';

const SIZE_CLASSES = {
  lg: 'w-28 h-28',
  md: 'w-20 h-20',
  sm: 'w-10 h-10',
};

const IMAGE_DIMENSIONS = {
  lg: 112,
  md: 80,
  sm: 64,
};

export default function Avatar({
  imageUrl,
  size,
  className,
}: {
  imageUrl: string;
  size: AvatarSize;
  className?: string;
}) {
  const dynamicClasses = SIZE_CLASSES[size] || 'w-10 h-10';
  const imageDimensions = IMAGE_DIMENSIONS[size] || 40;

  return (
    <div
      className={`${
        className || ''
      } relative ${dynamicClasses} overflow-hidden rounded-full group`}
    >
      <img
        className='rounded-full object-cover'
        width={imageDimensions}
        height={imageDimensions}
        src={`${!imageUrl ? imagePlaceholder : imageUrl}`}
        alt='User'
      />
    </div>
  );
}
