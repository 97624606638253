import Container from '../Container';
import {
  CButton,
  FormGroup,
  STATUS_CODE,
  capitalize,
  getTranslatedEmailPlaceholder,
  getTranslatedNamePlaceholder,
  getTranslatedPhonePlaceholder,
} from '@maw/utility';
import {
  ActionFunction,
  ActionFunctionArgs,
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from 'react-router-dom';
import { useTranslation } from '../../store/translation';
import { translations } from '../../data/translation';
import { getLocaleFromStorage, upsertResource } from '../../util/requests';
import { disableWhenLoading } from '../../util/helpers';
import { useEffect, useState } from 'react';
import { useMeta } from '../../hooks/metadata';
import Notification from '../Notification';

const serviceCategory = [
  {
    id: 'service_category',
    title: { en: 'Supermarket', fi: 'Myymälä' },
    value: 'supermarket',
  },
  {
    id: 'service_category',
    title: { en: 'Restaurant', fi: 'Ravintola' },
    value: 'restaurant',
  },
  {
    id: 'service_category',
    title: { en: 'Office', fi: 'Toimisto' },
    value: 'office',
  },
  { id: 'service_category', title: { en: 'Home', fi: 'Koti' }, value: 'home' },
  { id: 'service_category', title: { en: 'Other', fi: 'Muu' }, value: 'other' },
];

const yesOrNo = [
  { title: 'Select appropriate option', value: '' },
  { title: 'Yes', value: 'yes' },
  { title: 'No', value: 'no' },
];

export default function Enquiry() {
  const [show, setShow] = useState<string>('');
  const actionData: any = useActionData();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const { translate, lang } = useTranslation();

  function handleSelectCategory(category: string) {
    setShow(category);
  }

  function translateSelectOptions(title: string) {
    if (title.toLowerCase() === 'yes') {
      return translations[lang].form.select.hypothetical.yes;
    }
    if (title.toLowerCase() === 'no') {
      return translations[lang].form.select.hypothetical.no;
    }
    return translations[lang].form.select.title;
  }

  useEffect(() => {
    if (actionData?.statusCode === STATUS_CODE.ok) {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  }, [actionData?.statusCode, navigate]);

  useMeta({
    title: `${
      translate(translations[lang].metadata.enquiry.title) || 'Pyydä tarjous'
    }`,
    description: `${
      translate(translations[lang].metadata.enquiry.description) ||
      'Vastamme mahdollisimman nopeasti.'
    }`,
  });

  return (
    <Container>
      <div className='max-w-5xl m-auto rounded-lg p-4 overflow-hidden'>
        <div className='bg-green-50 py-24 sm:py-32 -mx-6 -mb-6 rounded-lg overflow-hidden'>
          <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <div className='mx-auto max-w-2xl sm:text-center'>
              <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
                {translate(translations[lang].enquiry.quote.title)}
              </h2>
              <p className='mt-6 text-lg leading-8 text-gray-600'>
                {translate(translations[lang].enquiry.quote.description)}
              </p>
            </div>
            <div className='px-4 py-8 mt-8 rounded-lg ring-1 ring-gray-600 bg-white'>
              <Notification data={actionData} />
              <Form
                className='mt-4'
                method='post'
                encType='multipart/form-data'
                noValidate
              >
                <FormGroup>
                  {/* Personal info */}
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='text'
                      id='first_name'
                      placeholder={getTranslatedNamePlaceholder(lang, 'first')}
                      title={capitalize(
                        translate(translations[lang].form.firstName)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='text'
                      id='last_name'
                      placeholder={getTranslatedNamePlaceholder(lang, 'last')}
                      title={capitalize(
                        translate(translations[lang].form.lastName)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='email'
                      id='email'
                      placeholder={getTranslatedEmailPlaceholder(lang)}
                      title={capitalize(
                        translate(translations[lang].form.email)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='text'
                      id='company_name'
                      placeholder={capitalize(
                        translate(translations[lang].form.companyName)
                      )}
                      title={capitalize(
                        translate(translations[lang].form.companyName)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='text'
                      id='contact_person'
                      title={capitalize(
                        translate(translations[lang].form.contactPerson)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                    <FormGroup.Input
                      isRequired
                      className='flex-1'
                      labelclassname='bg-white'
                      type='text'
                      id='address'
                      placeholder={capitalize(
                        translate(translations[lang].form.address)
                      )}
                      title={capitalize(
                        translate(translations[lang].form.address)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                    <FormGroup.Input
                      className='flex-1'
                      labelclassname='bg-white'
                      type='telephone'
                      id='telephone'
                      placeholder={getTranslatedPhonePlaceholder(lang)}
                      title={capitalize(
                        translate(translations[lang].form.telephone)
                      )}
                      data={actionData}
                      disabled={disableWhenLoading(navigation)}
                    />
                  </div>
                  {/* Radio group category select */}
                  <div className='mb-4'>
                    <h3 className='mb-4 font-semibold text-gray-900 dark:text-white'>
                      {`${capitalize(
                        translate(translations[lang].form.category)
                      )}`}
                    </h3>
                    <ul className='items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                      {serviceCategory.map((category) => (
                        <li
                          key={category.value}
                          className={`w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600 cursor-pointer`}
                          onClick={() => handleSelectCategory(category.value)}
                        >
                          <div className='flex items-center ps-3'>
                            <input
                              id={category.id}
                              type='radio'
                              value={category.value}
                              name={category.id}
                              className='w-4 h-4 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500'
                            />
                            <label
                              htmlFor={category.id}
                              className='w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                            >
                              {lang === 'en'
                                ? category.title.en
                                : category.title.fi}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Restaurant */}
                  {show && show === 'restaurant' && (
                    <div>
                      <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                        <FormGroup.Input
                          isRequired
                          className='flex-1'
                          labelclassname='bg-white'
                          type='number'
                          min={1.0}
                          step={2}
                          id='dining_area'
                          title={`${capitalize(
                            translate(translations[lang].form.diningArea)
                          )} (m³)`}
                          data={actionData}
                          disabled={disableWhenLoading(navigation)}
                        />
                        <FormGroup.Input
                          isRequired
                          className='flex-1'
                          labelclassname='bg-white'
                          type='number'
                          step={2}
                          min={1.0}
                          id='kitchen_size'
                          title={`${capitalize(
                            translate(translations[lang].form.kitchenSize)
                          )} (m³)`}
                          data={actionData}
                          disabled={disableWhenLoading(navigation)}
                        />
                      </div>
                      <div className='flex flex-col gap-x-2 md:flex-row md:space-x-2'>
                        <FormGroup.Input
                          isRequired
                          className='flex-1'
                          labelclassname='bg-white'
                          type='number'
                          id='cleaning_times_per_week'
                          min={1}
                          max={7}
                          title={capitalize(
                            translate(
                              translations[lang].form.cleaningTimesPerWeek
                            )
                          )}
                          data={actionData}
                          disabled={disableWhenLoading(navigation)}
                        />
                        <FormGroup.Select
                          isRequired
                          labelclassname='bg-white'
                          className='flex-1'
                          id='entrance_cubicle'
                          title={capitalize(
                            translate(translations[lang].form.entranceCubicle)
                          )}
                          data={actionData}
                          disabled={disableWhenLoading(navigation)}
                        >
                          {yesOrNo.map((option) => (
                            <option value={option.value} key={option.value}>
                              {capitalize(translateSelectOptions(option.value))}
                            </option>
                          ))}
                        </FormGroup.Select>
                        <FormGroup.Input
                          labelclassname='bg-white'
                          className='flex-1'
                          type='file'
                          id='attachments[]'
                          title={`${capitalize(
                            translate(translations[lang].form.attachment)
                          )} | max(10mb)`}
                          accept='.jpeg, .png, .jpg, .svg, .pdf, .xlsx, .doc, .txt'
                          multiple
                          data={actionData}
                          disabled={disableWhenLoading(navigation)}
                        />
                      </div>
                      <FormGroup.TextArea
                        isRequired
                        id='opening_times'
                        labelclassname='bg-white'
                        rows={5}
                        title={capitalize(
                          translate(translations[lang].form.openingTimes)
                        )}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                      <FormGroup.Input
                        isRequired
                        labelclassname='bg-white'
                        type='number'
                        id='number_of_rest_rooms'
                        min={0}
                        title={capitalize(
                          translate(translations[lang].form.numberOfRestRooms)
                        )}
                        data={actionData}
                        disabled={disableWhenLoading(navigation)}
                      />
                    </div>
                  )}
                  <FormGroup.TextArea
                    id='additional_information'
                    labelclassname='bg-white'
                    placeholder='...'
                    title={capitalize(
                      translate(translations[lang].form.moreInfo)
                    )}
                    data={actionData}
                    disabled={disableWhenLoading(navigation)}
                  />
                  {/* prevent bot from submitting form! */}
                  <input type='hidden' name='isBot' id='isBot' />
                  <CButton
                    rounded
                    type='submit'
                    variant='success'
                    disabled={disableWhenLoading(navigation)}
                  >
                    {navigation.state === 'submitting'
                      ? capitalize(translate(translations[lang].form.loading))
                      : capitalize(translate(translations[lang].btn.submit))}
                  </CButton>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionFunction> {
  const locale = getLocaleFromStorage();
  return await upsertResource({
    request,
    resource: 'enquiries',
    isFormData: true,
    locale,
  });
}
