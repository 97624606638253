import { capitalize, STATUS_CODE } from '@maw/utility';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from '../store/translation';
import { translations } from '../data/translation';

type NotificationProps = {
  data: {
    statusCode: number;
    error?:
      | { message: string[] | string }
      | { [key: string]: string | string[] };
  };
};
export default function Notification({ data }: NotificationProps) {
  const { translate, lang } = useTranslation();
  if (data && data.statusCode) {
    if (
      [STATUS_CODE.created, STATUS_CODE.noContent, STATUS_CODE.ok].includes(
        data.statusCode
      )
    ) {
      return (
        <div className='text-center p-4 rounded-md my-4 bg-green-200'>
          <div className='flex gap-x-4'>
            <ExclamationTriangleIcon
              className='h-6 w-6 text-green-700 bg-green-200'
              aria-hidden='true'
            />
            <p className='text-green-700'>
              {capitalize(
                translate(translations[lang].form.alert.type.success)
              )}
            </p>
          </div>
        </div>
      );
    }
    if (
      [
        STATUS_CODE.forbidden,
        STATUS_CODE.unauthorized,
        STATUS_CODE.conflict,
        STATUS_CODE.internalServerError,
        STATUS_CODE.badRequest,
        STATUS_CODE.serviceUnavailable,
        STATUS_CODE.notFound,
        STATUS_CODE.methodNotAllowed,
      ].includes(data.statusCode)
    ) {
      return (
        <div className='text-center p-4 rounded-md my-8 bg-red-200'>
          <div className='flex gap-x-4'>
            <ExclamationTriangleIcon
              className='h-6 w-6 text-red-700 bg-red-200'
              aria-hidden='true'
            />
            <p className='text-red-700'>
              {capitalize(
                translate(translations[lang].form.alert.type.failure)
              )}
            </p>
          </div>
        </div>
      );
    }
  }
  return null;
}
