import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/24/outline';
import Avatar from '../pages/Avatar';
import { ENV } from '../../util/constants';

export default function ReferenceCard({
  id,
  firstName,
  className,
  lastName,
  companyName,
  description,
  photo,
  title
}: Testimonial & {className?: string}) {
  return (
    <div
      className={`${
        className && className
      } relative w-full md:w-96 flex flex-col min-h-80 rounded-lg shadow-lg p-4 text-gray-500 bg-white mb-4`}
    >
      <div className='z-10'>{description}</div>
      <div className='flex justify-between mt-auto border-t-gray-200 border-t-2 pt-4 z-10'>
        <div>
          <div className='flex gap-x-2 font-bold'>
            <p className='inline-block'>{firstName}</p>
            <p className='inline-block'>{lastName}</p>
          </div>
          <p>{`${title}, ${companyName}`}</p>
        </div>
        {photo && <Avatar size='sm' imageUrl={`${ENV.assetsUrl}/${photo}`} />}
      </div>
      <ChatBubbleLeftEllipsisIcon
        className='absolute top-1/2 left-1/2 -translate-x-1/2 h-20 w-20 -translate-y-1/2 text-gray-100 -z-5'
        aria-hidden='true'
      />
    </div>
  );
}
