export const translations = {
  en: {
    companyName: 'MAW Siivouspalvelut Oy',
    for: 'for',
    welcome: 'welcome',
    jobTitle: 'position',
    location: 'location',
    deadline: 'deadline',
    employees: 'employees',
    employee: 'employee',
    client: 'client',
    serviceCategory: {
      supermarket: 'supermarket',
      office: 'office',
      home: 'home',
      other: 'other',
      restaurant: 'restaurant',
    },
    country: {
      finland: 'Finland',
    },
    positions: {
      open: 'Open Positions',
      no: 'There are no open positions',
    },
    privacyPolicy: {
      title: 'Privacy Policy',
      introduction:
        'This Privacy Policy is designed to inform you about the types of information we collect, how we use it, and how we protect your privacy.',
      description:
        'This website uses cookies to provide necessary site functionality and improve your online experience. By using this website, you agree to the use of cookies as outlined in our',
      category: {
        information: {
          title: 'Information We Collect',
          type: {
            personal: {
              title: 'Personal Information',
              description:
                'We may collect personal information such as names, addresses, email addresses, and phone numbers when voluntarily submitted by visitors through our online forms or during the donation process.',
            },
            nonPersonal: {
              title: 'Non-personal Information',
              description:
                'We may collect non-personal information, such as browser type, operating system, and IP address, to enhance the functionality of our Website and improve user experience.',
            },
          },
        },
        how: {
          title: 'How We Use Your Info',
          type: {
            communication: {
              title: 'Communication',
              description:
                'With your consent, we may use your contact information to send newsletters, updates, and other relevant information about our church and its activities.',
            },
            analytics: {
              title: 'Analytics',
              description:
                'Non-personal information may be used for statistical analysis, improving our Website, and tailoring content to better meet the needs of our visitors.',
            },
          },
        },
        protection: {
          title: 'How We Protect Your Information',
          description:
            'We implement reasonable security measures to protect your personal information. However, no data transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.',
        },
        thirdParty: {
          title: 'Third-Party Services',
          description:
            'We may use third-party services for payment processing and analytics. These services have their own privacy policies, and we encourage you to review them.',
        },
        cookie: {
          title: 'Cookies',
          description:
            'Our Website may use cookies to enhance your browsing experience. You can modify your browser settings to control the use of cookies. ',
        },
        yourChoice: {
          title: 'Your Choices',
          description:
            'You have the right to opt-out of receiving communications from us and to request access, correction, or deletion of your personal information.',
        },
        children: {
          title: "Children's Privacy",
          description:
            'Our Website is not directed at children under 13, and we do not knowingly collect personal information from children.',
        },
        thisPrivacy: {
          title: 'This Privacy Policy',
          description:
            'Changes to We may update this Privacy Policy to reflect changes in our practices. The revised policy will be effective immediately upon posting.',
        },
        contactUs: {
          title: 'Contact Us',
          description: {
            a: 'If you have any questions or concerns about this Privacy Policy, please contact us at',
            b: 'By using our Website, you consent to the terms of this Privacy Policy.',
          },
        },
      },
    },
    referenceTitle: 'We have received motivating feedback from our clients',
    form: {
      firstName: 'first name',
      lastName: 'last name',
      telephone: 'telephone',
      address: 'address',
      companyName: 'company name',
      category: 'category',
      description: 'description',
      email: 'email',
      diningArea: 'dining area',
      kitchenSize: 'kitchen size',
      cleaningTimesPerWeek: 'cleaning times per week',
      numberOfRestRooms: 'number of rest rooms',
      entranceCubicle: 'entrance cubicle',
      openingTimes: 'opening times',
      moreInfo: 'additional information',
      attachment: 'attachments',
      select: {
        title: 'select appropriate option',
        hypothetical: {
          yes: 'yes',
          no: 'no',
        },
      },
      loading: 'processing...',
      contactPerson: 'contact person',
      alert: {
        type: {
          success: 'Success!',
          failure: 'An error occurred. Please try again later!',
        },
      },
    },
    cta: 'Focus on what matters while we take care of the rest.',
    nav: {
      home: 'home',
      contacts: 'contacts',
      services: 'services',
      office: 'office',
      offices: 'offices',
      restaurant: 'restaurant',
      cleaning: 'cleaning',
      house: 'house',
      industrial: 'industrial',
      references: 'references',
      recruitment: 'recruitment',
      openPositions: 'open positions',
      aboutUs: 'about us',
      links: 'links',
      blogPosts: 'blog posts',
      enquiries: 'enquiries',
    },
    btn: {
      submit: 'submit',
      accept: 'accept',
      decline: 'decline',
      send: 'send',
      login: 'login',
      logout: 'logout',
      cta: 'check out',
      readMore: 'Read more',
      scheduleNow: 'Schedule now',
      applyNow: 'Apply now',
      loading: 'Loading',
    },
    blog: {
      title: 'Latest blog posts',
      subTitle: 'We write about anything germane to the earth of our planet.',
    },
    customers: {
      title: ' We have delivered excellent service for reputable businesses',
      subTitle:
        'This is just a tiny bit from the growing list of our satisfied customers.',
    },
    services: {
      title: 'Check out our array of services',
      subTitle: 'We have enough capacity to cover your cleaning needs.',
    },
    testimonial: {
      title: 'We have worked with reputable companies',
      subTitle: 'Hear from countless satisfied customers.',
    },
    enquiry: {
      quote: {
        title: 'Request a quote',
        description: "We'll respond as fast as possible",
      },
    },
    notFound: {
      backToHome: 'Go back home',
      description: "Sorry, we couldn't find the page you're looking for.",
      title: 'Page not found',
    },
    noData: 'No data',
    metadata: {
      home: {
        title: 'MAW Cleaning Services',
        description:
          'MAW Cleaning Services offers top-quality cleaning solutions.',
        keywords:
          'cleaning, cleaning services, MAW Cleaning, professional cleaning',
      },
      enquiry: {
        title: 'Request a quote',
        description: "We'll respond as fast as possible.",
      },
    },
  },
  fi: {
    companyName: 'MAW Siivouspalvelut Oy',
    welcome: 'tervetuloa',
    for: 'varten',
    jobTitle: 'tehtävä',
    location: 'sijainti',
    deadline: 'määräaika',
    employees: 'työntekijät',
    employee: 'työntekijä',
    client: 'asiakas',
    serviceCategory: {
      supermarket: 'myymälä',
      office: 'toimisto',
      home: 'koti',
      other: 'muu',
      restaurant: 'ravintola',
    },
    country: {
      finland: 'Suomi',
    },
    positions: {
      open: 'Avoinna olevat työpaikat',
      no: 'Ei avoimia työpaikkoja',
    },
    hypothetical: {
      yes: 'kyllä',
      no: 'ei',
    },
    privacyPolicy: {
      title: 'Tietosuojakäytäntö',
      introduction:
        'Tämä tietosuojakäytäntö on tarkoitettu tiedottamaan sinulle keräämiemme tietojen tyypeistä, niiden käytöstä ja yksityisyytesi suojaamisesta.',
      description:
        'Tämä verkkosivusto käyttää evästeitä tarjotakseen tarvittavan sivustotoiminnallisuuden ja parantaakseen verkkokokemustasi. Käyttämällä tätä verkkosivustoa hyväksyt evästeiden käytön, kuten kuvattu tietosuojakäytännössämme.',
      category: {
        information: {
          title: 'Keräämämme tiedot',
          type: {
            personal: {
              title: 'Henkilötiedot',
              description:
                'Voimme kerätä henkilötietoja, kuten nimiä, osoitteita, sähköpostiosoitteita ja puhelinnumeroita, kun kävijät vapaaehtoisesti antavat ne verkkolomakkeidemme kautta tai lahjoitusprosessin aikana.',
            },
            nonPersonal: {
              title: 'Ei-henkilökohtaiset tiedot',
              description:
                'Voimme kerätä ei-henkilökohtaisia tietoja, kuten selaimen tyyppi, käyttöjärjestelmä ja IP-osoite, parantaaksemme verkkosivustomme toiminnallisuutta ja käyttökokemusta.',
            },
          },
        },
        how: {
          title: 'Kuinka käytämme tietojasi',
          type: {
            communication: {
              title: 'Viestintä',
              description:
                'Suostumuksellasi voimme käyttää yhteystietojasi uutiskirjeiden, päivitysten ja muiden asiaankuuluvien tietojen lähettämiseen seurakunnastamme ja sen toiminnasta.',
            },
            analytics: {
              title: 'Analytiikka',
              description:
                'Ei-henkilökohtaisia tietoja voidaan käyttää tilastolliseen analyysiin, verkkosivustomme parantamiseen ja sisällön mukauttamiseen paremmin vastaamaan kävijöidemme tarpeita.',
            },
          },
        },
        protection: {
          title: 'Kuinka suojaamme tietosi',
          description:
            'Toteutamme kohtuulliset turvatoimenpiteet suojataksemme henkilötietosi. Mikään tiedonsiirto internetissä tai sähköinen tallennus ei kuitenkaan ole täysin turvallista, joten emme voi taata ehdotonta turvallisuutta.',
        },
        thirdParty: {
          title: 'Kolmannen osapuolen palvelut',
          description:
            'Saatamme käyttää kolmannen osapuolen palveluja maksujen käsittelyyn ja analytiikkaan. Näillä palveluilla on omat tietosuojakäytäntönsä, ja kehotamme sinua tutustumaan niihin.',
        },
        cookie: {
          title: 'Evästeet',
          description:
            'Verkkosivustomme saattaa käyttää evästeitä parantaakseen selauskokemustasi. Voit muokata selaimesi asetuksia hallitaksesi evästeiden käyttöä.',
        },
        yourChoice: {
          title: 'Valintasi',
          description:
            'Sinulla on oikeus kieltäytyä vastaanottamasta meiltä viestintää sekä pyytää pääsyä henkilötietoihisi, niiden korjaamista tai poistamista.',
        },
        children: {
          title: 'Lasten tietosuoja',
          description: 'Verkkosivustomme ei ole suunnattu alle 13-vuotiaille.',
        },
        thisPrivacy: {
          title: 'Tämä tietosuojakäytäntö',
          description:
            'Voimme päivittää tätä tietosuojakäytäntöä heijastamaan käytäntöjemme muutoksia. Päivitetty käytäntö tulee voimaan välittömästi sen julkaisemisen jälkeen.',
        },
        contactUs: {
          title: 'Ota yhteyttä',
          description: {
            a: 'Jos sinulla on kysymyksiä tai huolenaiheita tästä tietosuojakäytännöstä, ota meihin yhteyttä osoitteessa',
            b: 'Käyttämällä verkkosivustoamme hyväksyt tämän tietosuojakäytännön ehdot.',
          },
        },
      },
    },
    referenceTitle: 'Olemme saaneet motivoivaa palautetta asiakkailtamme',
    form: {
      firstName: 'etumini',
      lastName: 'sukunimi',
      address: 'osoite',
      email: 'sähköposti',
      companyName: 'yritys',
      description: 'selvitys',
      category: 'luokka',
      telephone: 'puhelin',
      diningArea: 'ruokailutila',
      kitchenSize: 'keittiön koko',
      cleaningTimesPerWeek: 'siivouskerrat viikossa',
      numberOfRestRooms: 'vessojen määrä',
      entranceCubicle: 'sisääntulokoppi',
      openingTimes: 'aukioloajat',
      attachment: 'tiedostot',
      moreInfo: 'lisätiedot',
      select: {
        title: 'valitse sopiva vaihtoehto',
        hypothetical: {
          yes: 'kyllä',
          no: 'ei',
        },
      },
      loading: 'käsitellään...',
      contactPerson: 'yhteyshenkilö',
      alert: {
        type: {
          success: 'Onnistui!',
          failure: 'Tapahtui virhe. Yritä myöhemmin uudelleen',
        },
      },
    },
    cta: 'Keskity siihen, mikä on tärkeää, ja me hoidamme loput.',
    nav: {
      home: 'koti sivu',
      contacts: 'yhteystiedot',
      services: 'palvelut',
      office: 'toimisto',
      offices: 'toimistot',
      restaurant: 'ravintola',
      cleaning: 'siivous',
      house: 'koti',
      industrial: 'teollisuuden',
      references: 'suositukset',
      recruitment: 'rekryitointi',
      openPositions: 'avoimet työpaikkaat',
      aboutUs: 'meistä',
      links: 'linkit',
      blogPosts: 'blogikirjoitukset',
      enquiries: 'tiedustelu',
    },
    btn: {
      submit: 'lähetä',
      accept: 'hyväksy',
      decline: 'hylkää',
      send: 'lähetä',
      login: 'kirjoita sisään',
      logout: 'kirjoita ulos',
      cta: 'katsokaa',
      readMore: 'Lue lisää',
      scheduleNow: 'Varaa nyt',
      applyNow: 'Hae nyt',
      loading: 'Ladataan',
    },
    blog: {
      title: 'Viimeisimmät blogikirjoitukset',
      subTitle: 'Kirjoitamme kaikesta, mikä liittyy planeettamme terveyteen.',
    },
    customers: {
      title:
        'Olemme toimittaneet erinomaista palvelua arvostetuille yrityksille',
      subTitle:
        'Tämä on vain pieni osa kasvavasta tyytyväisten asiakkaidemme joukosta.',
    },
    services: {
      title: 'Tutustu palveluvalikoimaamme',
      subTitle:
        'Meillä on riittävästi kapasiteettia kattamaan siivoustarpeesi.',
    },
    testimonial: {
      title: 'Olemme työskennelleet arvostettujen yritysten kanssa',
      subTitle: 'Kuule lukemattomien tyytyväisten asiakkaiden kokemuksia.',
    },
    enquiry: {
      quote: {
        title: 'Pyydä tarjous',
        description: 'Vastamme mahdollisimman nopeasti.',
      },
    },
    notFound: {
      backToHome: 'Palaa etusivulle',
      description: 'Valitettavasti emme löytäneet etsimääsi sivua.',
      title: 'Sivua ei löytynyt',
    },
    noData: 'Ei tietoa',
    metadata: {
      home: {
        title: 'MAW Siivouspalvelut',
        description:
          'MAW Siivouspalvelut tarjoaa korkealaatuisia siivousratkaisuja.',
        keywords:
          'siivous, siivouspalvelut, MAW Siivous, ammattimainen siivous',
      },
      enquiry: {
        title: 'Pyydä tarjous',
        description: 'Vastamme mahdollisimman nopeasti.',
      },
    },
  },
};
